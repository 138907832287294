<template>
  <div>
    <!-- pc -->
    <div class="page-header1 d-none d-sm-block">
      <carousel-section></carousel-section>
    </div>

    <!-- 手机 -->
    <div class="page-header page-header-custom1 d-block d-sm-none">
      <carousel-section></carousel-section>
    </div>

    <div class="section section-team text-center">
      <div class="container">
        <h3 class="title">{{positionMap['index-team'].name}}</h3>
      </div>
    </div>

    <div class="page-header page-header-custom2 d-block d-sm-none">
     <banner
        class="page-header-image" :style="'background-image: url('+ positionMap['index-team'].val +');height:450px !important'"
      >
      </banner>
      <div class="content-center">
        <div class="content-center brand">
          <h1 class="h1-seo" style="text-align: left">核心团队</h1>
          <h5 style="text-align: left">
            核心团队由多位海外顶尖科学家组成，拥有多年海外高能物理、Pet探测技术研究、ASIC/SiPM/SPAD芯片设计等行业工作经验。 
          </h5>
          <h5 style="text-align: left">
            致力于研发超高性能的ASIC/SiPM/SPAD，成功运用于PET探测、激光雷达、消费电子等领域。
          </h5>
          
        </div>
      </div>
    </div>


    <div class="page-header page-header-custom d-none d-sm-block">
      <banner
        class="page-header-image"
        style="background-image: url('img/index/hexintuandui@2x.png')"
      >
      </banner>
    </div>

    <div class="section section-team text-center">
      <div class="container">
        <h3 class="title">提供服务</h3>
        <p class="description">提供专业的咨询与定制服务
</p>
        <p class="description bottomSpace">
          提供PET用全数字处理系统集成ASIC模块
        </p>        
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player1">
                <img
                  src="img/index/ASIC@2x.png"
                  class="img-fluid"
                />
                <!-- <div class="whiteFont serviceDes">
                <h4 class="title boldFont">ASIC模块</h4>
                <h6 >成熟的超高性能</h6>
                <h6 >ASIC模块供应</h6>
                </div> -->
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player1">
                <img
                  src="img/index/SPAD@2x.png"
                  class="img-fluid"
                />
                <!-- <div class="whiteFont serviceDes">
                <h4 class="title boldFont">SPAD模块</h4>
                <h6 >业界领先的高探测</h6>
                <h6 >效率的SPAD模块</h6>
                </div> -->
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player1">
                <img
                  src="img/index/fananzixun@2x.png"
                  class="img-fluid"
                />
                <!-- <div class="whiteFont serviceDes">
                <h4 class="title boldFont">方案咨询</h4>
                <h6 >提供业界领先的技术</h6>
                <h6 >指导与咨询服务</h6>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <baiduMap></baiduMap>
  </div>
</template>
<script>
const WEBAPI = require("apifm-webapi");
WEBAPI.init2('https://api.it120.cc','bamei');

import { Button, FormGroupInput } from "@/components";
import Banner from '@/components/Banner';
import BaiduMap from '@/components/Map';
import CarouselSection from './components/CarouselSection';
export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Banner,
    BaiduMap,
    CarouselSection
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
      positionMap: {
        'index-team': {}
      }
    };
  },
  mounted() {
    this.adPositionBatch()
  },
  methods:{
    goProduct(){
      this.$router.push({
        path:'/product',
        query: {}
      })
    },
    goService(){
      this.$router.push({
        path:'/service',
        query: {}
      })
    },
    async adPositionBatch() {
      const res = await WEBAPI.adPositionBatch('index-team')
      if (res.code == 0) {
        res.data.forEach(ele => {
          this.positionMap[ele.key] = ele
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>

.cursor{
  cursor: pointer;
}

.title{
  padding: 0 !important; 
}

.bannerImg{
    background: url('../../public/img/index/hexintuandui@2x.png');
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 720px;
    z-index: -1;
}
p{
  margin:0
}

h6{
  line-height: 10px;
}

.whiteFont{
  color:white
}

.boldFont{
  font-weight: bold;
}
.bottomSpace{
  margin-bottom: 40px;
}
.team-player1{
  img{
    margin-bottom: 20px;
  }
  .title{
    padding: 0 !important;
  }
}

.team-player2{
  img{
    margin-bottom: 20px;
  }
  .title{
    padding: 0 !important;
  }
}

.serviceDes{
  margin-top: -200px;
  margin-bottom: 90px;
}

.image-container{
    background-size: cover;
    background-position: center center;
    width: 370px;
    height: 540px;
    z-index: -1;
    .description{
      margin-top: 300px;
      position: absolute;
      width: 370px;
      p{
        margin: 0;
        font-size: 26px;
        line-height: 20px;
      }
      .title{
        font-size: 28px;
        margin-bottom: 17px;
      }
    }
}

.map{
  width: 100%;
  height:460px;
}

.page-header1{
  max-height: auto !important;
  min-height: auto !important;
}

.page-header-custom1{
  max-height: 300px;
  min-height: 300px;
}

.page-header-custom2{
  max-height: 450px;
  min-height: 450px;
}
</style>
