<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="team">
        <div class="row">
          <div class="col-md-4 contact">CONTACT US</div>
          <div class="col-md-4 info">
            <p style="color: #FFFFFF !important;">邮箱：market@microparity.com</p>
            <p style="color: #FFFFFF !important;">电话：0571-56681256</p>
            <!-- <p>地址：杭州市滨江区浦沿街道东信大道66号5号楼301室</p> -->
          </div>
          <div class="col-md-4" style="display: flex;justify-content: center;">
            <img class="cur" src="../../public/ma.jpg" style="width:100px">
          <!-- <a
            class="btn btn-neutral btn-icon btn-twitter btn-round "
            rel="tooltip"
          >
            <i class="fab fa-twitter"></i>
          </a>
          <a
            class="btn btn-neutral btn-icon btn-facebook btn-round "
            rel="tooltip"
          >
            <i class="fab fa-facebook-square"></i>
          </a>
          <a
            class="btn btn-neutral btn-icon btn-linkedin  btn-round"
            rel="tooltip"
          >
            <i class="fab fa-linkedin"></i>
          </a>
          <a
            class="btn btn-neutral btn-icon btn-github btn-round "
            rel="tooltip"
          >
            <i class="fab fa-skype"></i>
          </a> -->
        

          </div>
        </div>
        <div class="row">
          <div class="col-md-12 bottomBrand">
            © 2017 microparity. All Rights Reserved 浙ICP备17044984-1
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style lang="scss" scoped>
.footer {
  padding: 50px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
p {
  padding: 0 !important;
  margin: 0 !important;
}

.contact{
  font-size: 20px;
}

.info{
  height: 60px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
}

.info p{
  color: #FFFFFF;

}

.bottomBrand {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff !important;
  margin-top:20px;
}
</style>
