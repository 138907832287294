<template>
  <div>
    <!-- pc -->
    <div class="section d-none d-sm-block" id="carousel">
    <el-carousel height="500px">
      <el-carousel-item v-for="item in bannersList" :key="item.id">
        <a v-if="item.linkUrl" :href="item.linkUrl">
          <img v-if="item.linkUrl" class="d-block" :src="item.picUrl" :alt="item.remark" />
        </a>
        <img v-else class="d-block" :src="item.picUrl" :alt="item.remark" />
        <div class="carousel-caption d-none d-md-block">
          <h5>{{ item.remark }}</h5>
        </div>
      </el-carousel-item>
    </el-carousel>
    </div>
    <!-- 手机 -->
    <el-carousel class=" d-block d-sm-none pad">
      <el-carousel-item v-for="item in bannersList" :key="item.id">
        <a v-if="item.linkUrl" :href="item.linkUrl">
          <img v-if="item.linkUrl" class="d-block" :src="item.picUrl" :alt="item.remark" />
        </a>
        <img v-else class="d-block2" :src="item.picUrl" :alt="item.remark" />
        <div class="carousel-caption d-none d-md-block">
          <h5>{{ item.remark }}</h5>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { Carousel, CarouselItem } from 'element-ui';
const WEBAPI = require("apifm-webapi");
WEBAPI.init("bamei");

export default {
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem
  },
  data() {
    return {
      bannersList: []
    };
  },
  mounted() {
    this.banners();
  },
  methods: {
    async banners() {
      const res = await WEBAPI.banners()
      if (res.code === 0) {
        this.bannersList = res.data
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.section {
  background-color: #000;
  padding: 70px 0 0 0 !important;
}
// .el-carousel__container {
//   height: 500px !important;
// }
.d-block {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.d-block2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pad{
  padding: 40px 0 0 0;
}
</style>
