const TokenKey = 'TK-RJ-Token10'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function getUid() {
  return localStorage.getItem(TokenKey + '_UID')
}

export function setUid(uid) {
  return localStorage.setItem(TokenKey + '_UID', uid)
}

export function getMobile() {
  return localStorage.getItem(TokenKey + '_mobile')
}

export function setMobile(mobile) {
  return localStorage.setItem(TokenKey + '_mobile', mobile)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function getBuyNowInfo() {
  return localStorage.getItem(TokenKey + '_buyNowInfo')
}

export function setBuyNowInfo(buyNowInfo) {
  return localStorage.setItem(TokenKey + '_buyNowInfo', buyNowInfo)
}

export function getPickCount() {
  return localStorage.getItem(TokenKey + '_pickCount')
}

export function setPickCount(pickCount) {
  return localStorage.setItem(TokenKey + '_pickCount', pickCount)
}

export function getNick() {
  return localStorage.getItem(TokenKey + '_nick')
}

export function setNick(nick) {
  return localStorage.setItem(TokenKey + '_nick', nick)
}