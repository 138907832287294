<template>
  <div v-if="newsDetail">
    <div class="page-header page-header-custom" style="display: none">
      <banner
        class="page-header-image"
        style="background-image: url('img/about/aboustus@2x.png')"
      >
      </banner>
    </div>

    <div
      class="row"
      style="background: #f0f0f0; padding: 20px 0; margin-top: 110px"
    >
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <nav aria-label="breadcrumb" role="navigation">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">首页</a></li>
            <li class="breadcrumb-item"><a href="/join">加入我们</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              {{newsDetail.title}}
            </li>
          </ol>
        </nav>
        <div
          class="section section-team"
          style="margin-bottom: 20px; padding-left: 32px; padding-right: 32px"
        >
          <h3 class="title text-center">{{newsDetail.title}}</h3>
          <div
            style="
              font-size: 14px;
              font-weight: 400;
              margin: -30px 0 30px 0;
              text-align: center;
              display: block;
              float: left;
              width: 100%;
            "
          >
            {{newsDetail.dateAdd}}
          </div>

          <div v-html="newsDetail.content" class="content"></div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import Banner from "@/components/Banner";
const WEBAPI = require("apifm-webapi");
WEBAPI.init2("https://api.it120.cc", "bamei");
WEBAPI.setMerchantId(61);

export default {
  name: "detailJob",
  bodyClass: "about-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Banner,
  },
  data() {
    return {
      newsDetail: undefined
    };
  },
  mounted() {
    this.cmsArticleDetail(this.$route.query.id)
  },
  methods: {
    async cmsArticleDetail(id) {
      const res = await WEBAPI.cmsArticleDetailV3({
        id
      });
      this.newsDetail = res.data.info
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  line-height: 1.61em !important;
  font-weight: 400 !important;
  font-size: 1.2em !important;
}
.content {
  padding: 8px;
  line-height: 1.61em;
  font-weight: 400;
  font-size: 1.2em;
  p {
    line-height: 1.61em;
    font-weight: 400;
    font-size: 1.2em;
  }
}
.page-header.page-header-custom {
  min-height: 280px !important;
  max-height: 280px !important;
}

.page-header-image {
  height: 280px !important;
  margin-top: 60px;
}

.flex-container {
  display: flex;
  align-items: center;
}

.title {
  padding: 0 !important;
}

.img-fluid {
  max-width: 80px !important;
}
p {
  margin: 0;
}

h6 {
  line-height: 10px;
}

.whiteFont {
  color: white;
}

.boldFont {
  font-weight: bold;
}
.bottomSpace {
  margin-bottom: 40px;
}
.team-player1 {
  img {
    margin-bottom: 20px;
  }
  .title {
    padding: 0 !important;
  }
}

.team-player2 {
  img {
    margin-bottom: 20px;
  }
  .title {
    padding: 0 !important;
  }
}

.serviceDes {
  margin-top: -200px;
  margin-bottom: 90px;
}

.description {
  color: #1e2433 !important;
  text-align: left !important;
}

.section {
  // padding:70px !important;
}

.map {
  width: 100%;
  height: 460px;
}

.left-align {
  // text-align: left;
}
</style>
