<template>
  <div>
    <div class="page-header page-header-custom">
      <banner
        class="page-header-image"
        :style="'background-image: url('+ positionMap['page_header_pic_comment'].val +')'"
      >
      </banner>
    </div>

    <div class="section section-team text-center" style="padding:0;margin-bottom:60px">
      <div class="row" style="padding: 20px 0;margin:0 !important">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <nav aria-label="breadcrumb" role="navigation">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">首页</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                在线留言
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="container">
        <div class="section section-contact-us text-center" style="padding: 40px 0;">
          <div class="container">
            <h2 class="title">留言反馈</h2>
            <p class="description" style="margin-bottom: 20px;">您的留言反馈对我们非常重要！</p>
            <div class="row">
              <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
                <fg-input
                  class="input-lg no-border"
                  placeholder="您的姓名"
                  v-model="form.firstName"
                  addon-left-icon="now-ui-icons users_circle-08"
                >
                </fg-input>
                <fg-input
                  class="input-lg no-border"
                  placeholder="您的Email地址"
                  v-model="form.email"
                  addon-left-icon="now-ui-icons ui-1_email-85"
                >
                </fg-input>
                <div class="textarea-container">
                  <textarea
                    class="form-control"
                    name="name"
                    rows="4"
                    cols="80"
                    v-model="form.message"
                    placeholder="您的留言反馈..."
                  ></textarea>
                </div>
                <div class="send-button">
                  <n-button type="primary" round block size="lg" @click="addComment"
                    >提交反馈</n-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

     <!-- small modal -->
    <modal
      :show.sync="modals.mini"
      class="modal-primary"
      :show-close="false"
      headerClasses="justify-content-center"
      type="mini"
    >
      <div slot="header" class="modal-profile">
        <i class=" fa fa-check" aria-hidden="true"></i>
      </div>
      <p>提交成功</p>
    </modal>

    <modal
      :show.sync="modals.errorName"
      class="modal-primary"
      :show-close="false"
      headerClasses="justify-content-center"
      type="mini"
    >
      <div slot="header" class="modal-profile">
        <i class=" fa fa-times" aria-hidden="true"></i>
      </div>
      <p>请填写您的姓名</p>
    </modal>

    <modal
      :show.sync="modals.errorEmail"
      class="modal-primary"
      :show-close="false"
      headerClasses="justify-content-center"
      type="mini"
    >
      <div slot="header" class="modal-profile">
        <i class=" fa fa-times" aria-hidden="true"></i>
      </div>
      <p>请填写您的Email地址</p>
    </modal>

    <modal
      :show.sync="modals.errorComment"
      class="modal-primary"
      :show-close="false"
      headerClasses="justify-content-center"
      type="mini"
    >
      <div slot="header" class="modal-profile">
        <i class=" fa fa-times" aria-hidden="true"></i>
      </div>
      <p>请填写您的留言反馈</p>
    </modal>

  </div>
</template>
<script>
import { Button, FormGroupInput,Modal} from "@/components";
import { Card, Tabs, TabPane } from "@/components";
import { Popover } from "element-ui";
import Banner from "@/components/Banner";
import API from "@/utils/api";

const WEBAPI = require("apifm-webapi");
WEBAPI.init2("https://api.it120.cc", "bamei");

export default {
  name: "service",
  bodyClass: "service-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Popover.name]: Popover,
    Banner,
    Modal,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
      modals: {
        classic: false,
        mini: false,
        errorName:false,
        errorEmail:false,
        errorComment:false,
      },
      newsList: [],
      positionMap: {
        'page_header_pic_comment': {},
      },
    };
  },

  mounted() {
    this.adPositionBatch()
  },
  methods: {
    async addComment() {
      var that = this
      if(this.form.firstName == "")
      {
          this.modals.errorName = true
          setTimeout(res =>{
              that.modals.errorName = false
          },2000)
          return
      }
      if(this.form.email == "")
      {
          this.modals.errorEmail = true
          setTimeout(res =>{
              that.modals.errorEmail = false
          },2000)
          return
      }
      if(this.form.message == "")
      {
          this.modals.errorComment = true
          setTimeout(res =>{
              that.modals.errorComment = false
          },2000)
          return
      }

      var extJsonStr = {}
      extJsonStr['姓名'] = this.form.firstName
      extJsonStr['邮箱'] = this.form.email
      extJsonStr = JSON.stringify(extJsonStr)
      const res = await WEBAPI.addComment({
          content:this.form.message,
          extJsonStr:extJsonStr
      })
      if (res.code == 0) {
        this.modals.mini = true
        setTimeout(res => {
            this.modals.mini = false
        }, 2000);
      }
    },
    async adPositionBatch() {
      const res = await WEBAPI.adPositionBatch('page_header_pic_comment')
      if (res.code == 0) {
        res.data.forEach(ele => {
          this.positionMap[ele.key] = ele
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page-header.page-header-custom {
  min-height: 280px !important;
  max-height: 280px !important;
}

.page-header-image {
  height: 280px !important;
  margin-top: 60px;
}

.title {
  padding: 0 !important;
}

.bannerImg {
  background: url("../../public/img/index/hexintuandui@2x.png");
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 720px;
  z-index: -1;
}
p {
  margin: 0;
}

h6 {
  line-height: 10px;
}

.whiteFont {
  color: white;
}

.boldFont {
  font-weight: bold;
}
.bottomSpace {
  margin-bottom: 40px;
}
.team-player1 {
  img {
    margin-bottom: 20px;
  }
  .title {
    padding: 0 !important;
  }
}

.team-player2 {
  img {
    margin-bottom: 20px;
  }
  .title {
    padding: 0 !important;
  }
}

.serviceDes {
  margin-top: -200px;
  margin-bottom: 90px;
}

.image-container {
  background-size: cover;
  background-position: center center;
  width: 370px;
  height: 540px;
  z-index: -1;
  .description {
    margin-top: 300px;
    position: absolute;
    width: 370px;
    p {
      margin: 0;
      font-size: 26px;
      line-height: 20px;
    }
    .title {
      font-size: 28px;
      margin-bottom: 17px;
    }
  }
}

.map {
  width: 100%;
  height: 460px;
}

.tab-content.tab-content-padding {
  padding: 20px;
}

.title-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: -14px;
}

.title-btn {
  width: 100px;
  height: 34px;
  background: #527bbd;
  border-radius: 100px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-tab {
  padding: 0 20px 30px 20px;
  min-height: 200px;
  margin-bottom: 50px;

  p {
    text-align: left;
    font-size: 14px;
  }
  .title {
    text-align: left;
    font-size: 16px;
  }
  h4 {
    margin-top: 16px !important ;
  }

  .textoverflow1 {
    //只显示一行
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .textoverflow2 {
    //显示五行
    width: 100%;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
