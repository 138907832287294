<template>
  <div id="app">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="footer" />
  </div>
</template>
<script>
export default {};
</script>
<style>
b, strong {
  font-weight: bold !important;
}
.el-carousel__arrow i {
  font-size: 30px;
}
p{color: #333 !important;}

/* *{    font-family:  "SF Pro Display",Roboto,Noto,Arial,"PingFang SC","Hiragino Sans GB","Microsoft YaHei",sans-serif !important ;} */
</style>