<template>
  <div>
    <!-- <div class="page-header page-header-custom">
      <banner
        class="page-header-image"
        style="background-image: url('img/join/news@2x.png')"
      >
      </banner>
    </div> -->

    <div
      class="section section-team text-center"
      style="padding: 0; margin-top: 90px"
    >
      <div class="row" style="padding: 20px 0; margin: 0 !important">
        <div class="col-md-2"></div>
        <div class="col-md-8">
        </div>
      </div>
      <div class="container">
        <h3 class="title">应用方案</h3>
        <div v-html="solutionsProfile.content" class="content"></div>
        <div class="tabsCard-container">
          <div
            class="tabsCard"
            :class="id == item.id ? 'activeTabs' : ''"
            @click="getProduct(item.id)"
            v-for="item in categoryList"
            :key="item.id"
          >
            {{ item.name }}
          </div>
        </div>




        <div class="team d-block d-sm-none">
          <div class="row">
            <div class="col-md-12" v-for="item in productList" :key="item.id">
              <div class="card card-tab">
                
                  <img
                    :src="item.pic"
                    :alt="item.title"
                    class=""
                    style="width: 100% !important"
                  />
                

                <h4 class="title">{{ item.title }}</h4>
                <div v-html="item.content" class="content"></div>
              </div>
            </div>
          </div>
        </div>

         <div class="container-water-fall d-none d-sm-block">
          <waterfall
            :col="1"
            :gutterWidth="0"
            :data="productList"
            :isTransition="true"
            @loadmore="loadmore"
            @scroll="scroll"
          >
            <template>
              <div
                class="cell-item"
                v-for="(item, index) in productList"
                :key="index"
                @click="handelRouter(item.id)"
              >
                <!-- <img v-if="item.pic" :src="item.pic" alt="加载错误" />
                <div class="item-body">
                  <div class="item-descA">{{ item.title }}</div>
                  <div class="item-descB">{{ item.class }}</div>
                </div> -->

                <!-- -------------------- -->
                <div class="card-tab">
              
                  <img
                    :src="item.pic"
                    :alt="item.title"
                    class="img-fluid"
                    style="width: 100% !important"
                  />
           

                <h4 class="title">{{ item.title }}</h4>
                <div v-html="item.content" class="content"></div>
              </div>
                <!-- ------------------------- -->

              </div>
            </template>
          </waterfall>
        </div>


        
      </div>
    </div>

    
   

  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import { Card, Tabs, TabPane } from "@/components";
import { Popover } from "element-ui";
import Banner from "@/components/Banner";
import API from "@/utils/api";



const WEBAPI = require("apifm-webapi");
WEBAPI.init2("https://api.it120.cc", "bamei");
WEBAPI.setMerchantId(61);

export default {
  name: "service",
  bodyClass: "service-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Popover.name]: Popover,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
      productList: [],
      categoryList: [],
      id: "0",

      col:'2',
      page:1,
      solutionsProfile: undefined
    };
  },
  watch :{
    '$route': function (to, from) {
      this.loadData();
    }
  },
  mounted() {
    this.loadData()
    this.getCategories()
     this._solutionsProfile()
  },
  methods: {
    loadData() {
      const id = this.$route.query.id
      if (id) {
        this.getProduct(id);
      } else {
        this.getProduct("0");
      }
    },
    async getCategories() {
      const res = await WEBAPI.cmsCategories();
      if (res.code == 0) {
        var categories = res.data;
        var categoryList = [];
        var pid = "";
        categories.forEach((element) => {
          if (element.key == "yyfa") {
            pid = element.id;
          }
        });

        categories.forEach((element) => {
          if (element.pid == pid) {
            categoryList.push(element);
          }
        });

        this.categoryList = categoryList;
      }
    },
    async getProduct(id) {
      var postData = {
        pageSize: 100,
        hasContent: true,
      };
      if (id != "0") {
        postData.categoryId = id;
        this.id = id;
      } else {
        postData.categoryKey = "yyfa";
        this.id = 0;
      }
      const res = await WEBAPI.cmsArticlesV3(postData);
      if (res.code == 0) {
        this.productList = res.data.result;
      } else {
        this.productList = [];
      }
    },
    async _solutionsProfile() {
      const res = await WEBAPI.cmsPage('solutions_profile')
      this.solutionsProfile = res.data     
    },


    //布局
scroll(scrollData) {
// console.log(scrollData)
},
switchCol(col) {
this.col = col
// console.log(this.col)
},
loadmore(index) {
//这里是当滑动到底部时，再次请求接口，并且传page，返回的数据给dataList赋值
//简单粗暴的有了分页功能
}

  },
};
</script>
<style lang="scss" scoped>
.cell-item {
  margin: 10px 5px !important;
}

.page-header.page-header-custom {
  min-height: 280px !important;
  max-height: 280px !important;
}

.page-header-image {
  height: 280px !important;
  margin-top: 60px;
}

.title {
  padding: 0 !important;
}

.bannerImg {
  background: url("../../public/img/index/hexintuandui@2x.png");
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 720px;
  z-index: -1;
}
p {
  margin: 0;
}

h6 {
  line-height: 10px;
}

.whiteFont {
  color: white;
}

.boldFont {
  font-weight: bold;
}
.bottomSpace {
  margin-bottom: 40px;
}
.team-player1 {
  img {
    margin-bottom: 20px;
  }
  .title {
    padding: 0 !important;
  }
}

.team-player2 {
  img {
    margin-bottom: 20px;
  }
  .title {
    padding: 0 !important;
  }
}

.serviceDes {
  margin-top: -200px;
  margin-bottom: 90px;
}

.image-container {
  background-size: cover;
  background-position: center center;
  width: 370px;
  height: 540px;
  z-index: -1;
  .description {
    margin-top: 300px;
    position: absolute;
    width: 370px;
    p {
      margin: 0;
      font-size: 26px;
      line-height: 20px;
    }
    .title {
      font-size: 28px;
      margin-bottom: 17px;
    }
  }
}

.map {
  width: 100%;
  height: 460px;
}

.tab-content.tab-content-padding {
  padding: 20px;
}

.title-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: -14px;
}

.title-btn {
  width: 100px;
  height: 34px;
  background: #527bbd;
  border-radius: 100px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-tab {
  padding: 0 0 30px 0;
  min-height: 200px;
  p {
    text-align: left;
    font-size: 14px;
  }
  .title {
    font-size: 20px;
    background: rgba(0,0,0,.5);
    margin: -40px 0 0 0 !important;
    position: relative;
    z-index: 999;
    color: #fff;
    height: 40px;
    line-height: 40px;
        text-align: left;
    padding: 0 20px !important;


  }
  h4 {
    margin-top: 16px !important ;
  }

  .textoverflow {
    //只显示一行
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .textoverflow2 {
    //显示五行
    width: 100%;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .img-container {
    padding: 40px 60px 40px 60px;
    img {
      width: auto !important;
      margin: auto !important;
    }
  }

  .content {
    font-size: 14px;
    margin-bottom: 2px !important;
    text-align: left;
    padding: 20px 20px 0;
  }
  .content>strong{
      font-weight: bold;
  }
}

.tabsCard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.tabsCard {
  color: #888888;
  background-color: #f8f9fa;
  margin: 0;
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid transparent;
  border-radius: 30px;
  font-size: 14px;
  padding: 11px 23px;
  line-height: 1.5;
}

.tabsCard:hover {
  cursor: pointer;
}

.activeTabs {
  background-color: #007bff;
  box-shadow: 0px 5px 35px 0px argb(0 0 0 / 30%);
  color: #ffffff;
}


// ----------------------------------------



.container-water-fall{
padding: 10px 3%;
width: 100%;
}
.cell-item{
background: #fff;
overflow: hidden;
margin: 10% 0 10% 0.5%;
box-shadow: 0px 0px 1px 1px #e3e3e3;
img{
width: 80%;
// margin-left: 10%;
height: auto;
display: block;
}
}

.item-descA{
font-size: 17px;
color: rgba(46, 44, 42, 1);
line-height: 17px;
font-weight: bold;
margin: 11.5px 0 13px 9px;
}

.item-descB{
font-size: 12px;
font-weight: 500;
color: rgba(89, 89, 89, 1);
line-height: 17px;
margin: 11.5px 0 13px 9px;
}

.item-footer{
margin-top: 22px;
position: relative;
display: flex;
align-items: center;
}

.name{
max-width: 150px;
margin-left: 10px;
font-size: 14px;
color: #999;
}

.tech-list-ll .tab-bottom{
padding: 10px 0 !important;
height: 0 !important;
background: #fafafa !important;
}
</style>

