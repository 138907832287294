<template>
  <baidu-map class="map" :center="location" :zoom="zoom" style="display:none">
        <bm-marker :position="location" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
        <bm-label content="杭州宇称电子" :labelStyle="{color: '#527bbd', fontSize : '20px',border:'1px solid #527bbd',padding:'0 10px'}" :offset="{width: -35, height: 30}"/>
    </bm-marker>
    </baidu-map>
</template>
<script>
export default {
  name: 'bmap',
  data() {
    return {
      location: {
        lng: 120.150135,
        lat: 30.185109
      },
      zoom: 18,
    };
  },
};
</script>
