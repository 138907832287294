<template>
  <div>
    
    <div class="page-header page-header-custom">
      <banner
        class="page-header-image"
        :style="'background-image: url('+ positionMap['page_header_pic_about'].val +')'"
      >
      </banner>
    </div>

    <div class="row" style="background: #F0F0F0;padding:20px 0;margin:0 !important">
    <div class="col-md-2"></div>
    <div class="col-md-8">
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">首页</a></li>
        <li class="breadcrumb-item active" aria-current="page">公司简介</li>
      </ol>
    </nav>
    <div class="section section-team" style="margin-bottom:20px;padding-left:32px;padding-right:32px;">
      <h3 class="title text-center">公司介绍</h3>

      <img :src="positionMap['about'].val" style="width:100%">

      <div v-html="cultureData.content" class="content"></div>
    </div>

      <div class="section section-team" style="margin-bottom:20px;padding-left:32px;padding-right:32px;">
      <h3 class="title text-center">投资人股东</h3>

       <img v-for="(item, index) in friendlyPartnerList" :key="index" class="tz" :src="item.picUrl" >
    </div>

    <div class="section section-team" style="margin-bottom:20px;padding-left:32px;padding-right:32px;">
      <h3 class="title text-center">研究领域</h3>
      <div v-html="researchAreaData.content" class="content"></div>
    </div>

    <div class="section section-team text-center">
      <div class="container">
        <h3 class="title">主要设计产品</h3>
        <div v-html="productsData.content" class="content"></div>
      </div>
    </div>

    </div>
    <div class="col-md-2"></div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import Banner from '@/components/Banner';
const WEBAPI = require("apifm-webapi");
WEBAPI.init2('https://api.it120.cc','bamei');

export default {
  name: "about",
  bodyClass: "about-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Banner,
  },
  data() {
    return {
      cultureData: {},
      researchAreaData: {},
      productsData: {},
      form: {
        firstName: "",
        email: "",
        message: "",
      },
      positionMap: {
        'about': {},
        'page_header_pic_about': {},
      },
      friendlyPartnerList: undefined,
    };
  },
  mounted() {
    this.getCulture()
    this.researchArea()
    this.products()
    this.adPositionBatch()
    this._friendlyPartnerList()
  },
  methods: {
    async getCulture() {
      const res = await WEBAPI.cmsPage('about')
      this.cultureData = res.data     
    },
    async researchArea() {
      const res = await WEBAPI.cmsPage('researchArea')
      this.researchAreaData = res.data     
    },
    async products() {
      const res = await WEBAPI.cmsPage('products')
      this.productsData = res.data     
    },
    async adPositionBatch() {
      const res = await WEBAPI.adPositionBatch('about, page_header_pic_about')
      if (res.code == 0) {
        res.data.forEach(ele => {
          this.positionMap[ele.key] = ele
        });
      }
    },
    async _friendlyPartnerList() {
      const res = await WEBAPI.friendlyPartnerList()
      if (res.code == 0) {
        this.friendlyPartnerList = res.data
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  padding: 8px;
}
.page-header.page-header-custom {
  min-height: 280px !important;
  max-height: 280px !important;
}

.page-header-image {
  height: 280px !important;
  margin-top: 60px;
}

.flex-container {
  display: flex;
  align-items: center;
}

.title{
  padding: 0 !important; 
}

.img-fluid{
    max-width: 80px !important;
}
p{
  margin:0
}

h6{
  line-height: 10px;
}

.whiteFont{
  color:white
}

.boldFont{
  font-weight: bold;
}
.bottomSpace{
  margin-bottom: 40px;
}
.team-player1{
  img{
    margin-bottom: 20px;
  }
  .title{
    padding: 0 !important;
  }
}

.team-player2{
  img{
    margin-bottom: 20px;
  }
  .title{
    padding: 0 !important;
  }
}

.serviceDes{
  margin-top: -200px;
  margin-bottom: 90px;
}

.description{
  color: #1E2433 !important;
  text-align: left !important;
}

.section{
    // padding:70px !important;
}

.map{
  width: 100%;
  height:460px;
}

.left-align{
    // text-align: left;
}

.tz{ width: 20%; margin: 20px 5%;}
</style>
