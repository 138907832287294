<template>
  <div>
    <div class="page-header page-header-custom">
      <banner
        class="page-header-image"
        :style="'background-image: url('+ positionMap['page_header_pic_join'].val +')'"
      >
      </banner>
    </div>

    <div
      class="section section-team text-center"
      style="background: #f0f0f0; padding: 0"
    >
      <div
        class="row"
        style="background: #f0f0f0; padding: 20px 0; margin: 0 !important"
      >
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <nav aria-label="breadcrumb" role="navigation">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">首页</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                加入我们
              </li>
            </ol>
          </nav>

          <h3 class="title">职位招聘</h3>

          <p class="description hr">请把简历发送给HR专员</p>

          <div class="flex-container center-container bottomSpace">
            <div class="title-btn">hr@microparity.com</div>
          </div>

          <div class="newsList">
            <a v-for="item in jobList" :key="item.id" :href="'/detailJob?id=' + item.id">
              <p>
                <span>{{ item.title }}</span> <em>{{ item.dateAdd }}</em>
              </p>
            </a>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="team">
          <div class="row">
            <!-- <div class="col-md-6" v-for="item in jobList" :key="item.id">
              <div class="card card-tab">
                <div class="img-container">
                  <img :src="item.pic" class="img-fluid" />
                </div>

                <h4 class="title">{{ item.title }}</h4>
                <div v-html="item.content" class="content"></div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <baiduMap></baiduMap>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import { Carousel, CarouselItem } from "element-ui";
import Banner from "@/components/Banner";
import BaiduMap from "@/components/Map";

const WEBAPI = require("apifm-webapi");
WEBAPI.init2("https://api.it120.cc", "bamei");
WEBAPI.setMerchantId(61);

export default {
  name: "join",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    Banner,
    BaiduMap,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
      jobList: [],
      positionMap: {
        'about': {},
        'page_header_pic_join': {},
      },
    };
  },
  mounted() {
    this.getJobs();
    this.adPositionBatch()
  },
  methods: {
    async getJobs() {
      const res = await WEBAPI.cmsArticlesV3({
        pageSize: 100,
        categoryKey: "jobs",
        hasContent: true,
      });
      console.log(res);
      if (res.code == 0) {
        var jobsData = res.data.result;
        // newsData.forEach(element => {
        //   var str = element.dateAdd
        //   var list = str.split(" ")
        //   element.dateAdd = list[0]
        // });
        this.jobList = jobsData;
      }
    },
    godetail(item) {
      this.$router.push({
        path: "/detailJob",
        query: { id: item.id, rnd: Math.random() },
      });
    },
    async adPositionBatch() {
      const res = await WEBAPI.adPositionBatch('page_header_pic_join')
      if (res.code == 0) {
        res.data.forEach(ele => {
          this.positionMap[ele.key] = ele
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.newsList {
}
.newsList p {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ccc;
  display: block;
  float: left;
  margin: 0 0 20px !important;
}

.newsList p span {
  width: 80%;
  height: auto;
  display: block;
  color: #000;
  float: left;
  font-size: 16px;
  text-align: left;
}
.newsList p em {
  width: 20%;
  font-style: normal;
  height: auto;
  display: block;
  color: rgb(103, 103, 103);
  float: right;
  font-size: 16px;
  text-align: right;
}

.page-header.page-header-custom {
  min-height: 280px !important;
  max-height: 280px !important;
}

.page-header-image {
  height: 280px !important;
  margin-top: 60px;
}

.flex-container {
  display: flex;
  align-items: center;
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.title {
  padding: 0 !important;
}

.bannerImg {
  background: url("../../public/img/index/hexintuandui@2x.png");
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 720px;
  z-index: -1;
}
p {
  margin: 0 0 8px 0 !important;
  font-size: 14px !important;
}

h6 {
  line-height: 10px;
}

.whiteFont {
  color: white;
}

.boldFont {
  font-weight: bold;
}
.bottomSpace {
  margin-bottom: 40px;
}
.team-player1 {
  img {
    margin-bottom: 20px;
  }
  .title {
    padding: 0 !important;
  }
}

.team-player2 {
  img {
    margin-bottom: 20px;
  }
  .title {
    padding: 0 !important;
  }
}

.carousel-item {
  background-color: white;
}

.job-info {
  text-align: left;
  h6 {
    margin-top: 20px;
  }
  p {
    font-size: 14px;
  }
  .content {
    font-size: 14px;
    margin-bottom: 2px !important;
  }
}

.serviceDes {
  margin-top: -200px;
  margin-bottom: 90px;
}

.image-container {
  background-size: cover;
  background-position: center center;
  width: 370px;
  height: 540px;
  z-index: -1;
  .description {
    margin-top: 300px;
    position: absolute;
    width: 370px;
    p {
      margin: 0;
      font-size: 26px;
      line-height: 20px;
    }
    .title {
      font-size: 28px;
      margin-bottom: 17px;
    }
  }
}

.map {
  width: 100%;
  height: 460px;
}

.hr {
  color: black;
  font-size: 16px;
  font-weight: bold;
}

.title-btn {
  width: 300px;
  height: 34px;
  background: #527bbd;
  border-radius: 100px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.content {
  font-size: 14px;
  margin-bottom: 2px !important;
  text-align: left;
  padding: 0 20px;
}
</style>
