<template>
  <div>
    
    <div class="page-header page-header-custom">
      <banner
        class="page-header-image"
        :style="'background-image: url('+ positionMap['page_header_pic_culture'].val +')'"
      >
      </banner>
    </div>

    <div class="row" style="background: #F0F0F0;padding:20px 0;margin:0 !important">
    <div class="col-md-2"></div>
    <div class="col-md-8">
    
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">首页</a></li>
        <li class="breadcrumb-item active" aria-current="page">企业文化</li>
      </ol>
    </nav>

    <div class="section section-team text-center" style="margin-bottom:20px">
      <div class="container">
        <h3 class="title">{{cultureData.title}}</h3>
        <div v-html="cultureData.content" class="content"></div>
      </div>
    </div>


    </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import Banner from '@/components/Banner';

const WEBAPI = require("apifm-webapi");
WEBAPI.init2('https://api.it120.cc','bamei');

export default {
  name: "culture",
  bodyClass: "culture-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Banner,
  },
  data() {
    return {
        cultureData:[],
        positionMap: {
        'about': {},
        'page_header_pic_culture': {},
      },
    };
  },
   mounted() {
    this.getCulture()
    this.adPositionBatch()
  },
  methods: {
    async getCulture() {
      const res = await WEBAPI.cmsPage('culture')
      console.log(res); 
      this.cultureData = res.data     
    },
    async adPositionBatch() {
      const res = await WEBAPI.adPositionBatch('about, page_header_pic_culture')
      if (res.code == 0) {
        res.data.forEach(ele => {
          this.positionMap[ele.key] = ele
        });
      }
    },
  }
};
</script>
<style lang="scss" scoped>

.page-header.page-header-custom {
  min-height: 280px !important;
  max-height: 280px !important;
}

.page-header-image {
  height: 280px !important;
  margin-top: 60px;
}

.flex-container {
  display: flex;
  align-items: center;
}

.title{
  padding: 0 !important; 
}

.img-fluid{
    max-width: 80px !important;
}
p{
  margin:0
}

h6{
  line-height: 10px;
}

.whiteFont{
  color:white
}

.boldFont{
  font-weight: bold;
}
.bottomSpace{
  margin-bottom: 40px;
}
.team-player1{
  img{
    margin-bottom: 20px;
  }
  .title{
    padding: 0 !important;
  }
}

.team-player2{
  img{
    margin-bottom: 20px;
  }
  .title{
    padding: 0 !important;
  }
}

.serviceDes{
  margin-top: -200px;
  margin-bottom: 90px;
}

.description{
    font-size: 14px !important;
}

.section{
    // padding:70px !important;
}

.image-container{
    background-size: cover;
    background-position: center center;
    width: 370px;
    height: 540px;
    z-index: -1;
    .description{
      margin-top: 300px;
      position: absolute;
      width: 370px;
      p{
        margin: 0;
        font-size: 26px;
        line-height: 20px;
      }
      .title{
        font-size: 28px;
        margin-bottom: 17px;
      }
    }
}

.map{
  width: 100%;
  height:460px;
}

.left-align{
    // text-align: left;
}
</style>
