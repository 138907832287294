<template>
  <div>
    <div class="section section-team text-center">
      <div class="container">
        <h3 class="title">提供服务</h3>
        <p class="description">致力于为PET-CT与PET-MR生产厂商提供成熟可靠的ASIC解决方案
</p>
        <p class="description">
          致力于将射线探测数模混合(System on Chip, Soc)
        </p>
        <p class="description bottomSpace">
          专用集成电路芯片(Application Specific Integrated Circuits, ASIC)应用到广泛的民用领域中
        </p>        
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player1">
                <img
                  src="img/index/ASIC@2x.png"
                  alt="Thumbnail Image"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player1">
                <img
                  src="img/index/SPAD@2x.png"
                  alt="Thumbnail Image"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player1">
                <img
                  src="img/index/fananzixun@2x.png"
                  alt="Thumbnail Image"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "service",
  bodyClass: "service-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
<style lang="scss" scoped>

.title{
  padding: 0 !important; 
}

.bannerImg{
    background: url('../../public/img/index/hexintuandui@2x.png');
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 720px;
    z-index: -1;
}
p{
  margin:0
}

h6{
  line-height: 10px;
}

.whiteFont{
  color:white
}

.boldFont{
  font-weight: bold;
}
.bottomSpace{
  margin-bottom: 40px;
}
.team-player1{
  img{
    margin-bottom: 20px;
  }
  .title{
    padding: 0 !important;
  }
}

.team-player2{
  img{
    margin-bottom: 20px;
  }
  .title{
    padding: 0 !important;
  }
}

.serviceDes{
  margin-top: -200px;
  margin-bottom: 90px;
}

.image-container{
    background-size: cover;
    background-position: center center;
    width: 370px;
    height: 540px;
    z-index: -1;
    .description{
      margin-top: 300px;
      position: absolute;
      width: 370px;
      p{
        margin: 0;
        font-size: 26px;
        line-height: 20px;
      }
      .title{
        font-size: 28px;
        margin-bottom: 17px;
      }
    }
}

.map{
  width: 100%;
  height:460px;
}
</style>
