<template>
  <div>
    <div class="page-header page-header-custom">
      <banner
        class="page-header-image"
        :style="'background-image: url('+ positionMap['page_header_pic_question'].val +')'"
      >
      </banner>
    </div>

    <div class="section section-team text-center" style="padding: 0">
      <div class="row" style="padding: 20px 0;margin:0 !important">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <nav aria-label="breadcrumb" role="navigation">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">首页</a></li>
              <li class="breadcrumb-item active" aria-current="page">
                常见问题
              </li>
            </ol>
          </nav>

          <blockquote class="blockquote" v-for="item in newsList" :key="item.id">
            <p class="mb-0" style="font-weight:bold;color:black;font-size:16px">
              {{ item.title }}
            </p>
            <footer class="bfooter">
              {{ item.descript }}
            </footer>
          </blockquote>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from "@/components";
import { Card, Tabs, TabPane } from "@/components";
import { Popover } from "element-ui";
import Banner from "@/components/Banner";
import API from "@/utils/api";

const WEBAPI = require("apifm-webapi");
WEBAPI.init2("https://api.it120.cc", "bamei");
WEBAPI.setMerchantId(61);

export default {
  name: "service",
  bodyClass: "service-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Popover.name]: Popover,
    Banner,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
      newsList: [],
      positionMap: {
        'about': {},
        'page_header_pic_question': {},
      },
    };
  },

  mounted() {
    this.getNews()
     this.adPositionBatch()
  },
  methods: {
    async getNews() {
      const res = await WEBAPI.cmsArticlesV3({
        pageSize: 100,
        categoryKey: "qa",
      });
      console.log(res);
      if (res.code == 0) {
        var newsData = res.data.result;
        newsData.forEach((element) => {
          var str = element.dateAdd;
          var list = str.split(" ");
          element.dateAdd = list[0];
        });
        this.newsList = newsData;
      }
    },
    async adPositionBatch() {
      const res = await WEBAPI.adPositionBatch('page_header_pic_question')
      if (res.code == 0) {
        res.data.forEach(ele => {
          this.positionMap[ele.key] = ele
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bfooter{
        display: block;
    font-size: 80%;
    color: #6c757d;
}

.page-header.page-header-custom {
  min-height: 280px !important;
  max-height: 280px !important;
}

.page-header-image {
  height: 280px !important;
  margin-top: 60px;
}

.title {
  padding: 0 !important;
}

.bannerImg {
  background: url("../../public/img/index/hexintuandui@2x.png");
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 720px;
  z-index: -1;
}
p {
  margin: 0;
}

h6 {
  line-height: 10px;
}

.whiteFont {
  color: white;
}

.boldFont {
  font-weight: bold;
}
.bottomSpace {
  margin-bottom: 40px;
}
.team-player1 {
  img {
    margin-bottom: 20px;
  }
  .title {
    padding: 0 !important;
  }
}

.team-player2 {
  img {
    margin-bottom: 20px;
  }
  .title {
    padding: 0 !important;
  }
}

.serviceDes {
  margin-top: -200px;
  margin-bottom: 90px;
}

.image-container {
  background-size: cover;
  background-position: center center;
  width: 370px;
  height: 540px;
  z-index: -1;
  .description {
    margin-top: 300px;
    position: absolute;
    width: 370px;
    p {
      margin: 0;
      font-size: 26px;
      line-height: 20px;
    }
    .title {
      font-size: 28px;
      margin-bottom: 17px;
    }
  }
}

.map {
  width: 100%;
  height: 460px;
}

.tab-content.tab-content-padding {
  padding: 20px;
}

.title-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: -14px;
}

.title-btn {
  width: 100px;
  height: 34px;
  background: #527bbd;
  border-radius: 100px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-tab {
  padding: 0 20px 30px 20px;
  min-height: 200px;
  margin-bottom: 50px;

  p {
    text-align: left;
    font-size: 14px;
  }
  .title {
    text-align: left;
    font-size: 16px;
  }
  h4 {
    margin-top: 16px !important ;
  }

  .textoverflow1 {
    //只显示一行
    display: inline-block;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .textoverflow2 {
    //显示五行
    width: 100%;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
