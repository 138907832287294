<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link class="navbar-brand" to="/">
        <img src="img/index/logo@2x.png" class="invision-logo" />
      </router-link>
    </template>
    <template slot="navbar-menu">
      <!-- <li class="nav-item">
        <a class="nav-link" @click="goIndex" hover="hover">
          <p>首页</p>
        </a>
      </li> -->

      <drop-down tag="li" title="关于我们" class="nav-item">
        <nav-link to="/about"> 公司简介 </nav-link>
        <nav-link to="/culture"> 企业文化 </nav-link>
      </drop-down>

      <drop-down tag="li" title="产品中心" class="nav-item">
        <nav-link v-for="item in proCategoryList" :key="item.id">
          <div @click="goProduct(item.id)">{{ item.name }}</div>
        </nav-link>
      </drop-down>


      <drop-down tag="li" title="解决方案" class="nav-item">
        <nav-link v-for="item in yyfaList" :key="item.id">
          <div @click="goYyfa(item.id)">{{ item.name }}</div>
        </nav-link>
      </drop-down>


      <drop-down tag="li" title="技术支持" class="nav-item">
        <nav-link to="/question"> 常见问题 </nav-link>
        <nav-link to="/comment"> 在线留言 </nav-link>
      </drop-down>

      <!-- <drop-down tag="li" title="公司新闻" class="nav-item">
        <nav-link to="/news"> 公司新闻 </nav-link>
        <nav-link to="/hangye"> 行业咨询 </nav-link>
      </drop-down> -->

      <li class="nav-item">
        <a class="nav-link" @click="goNews" hover="hover">
          <p>新闻资讯</p>
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link" @click="goJoin" hover="hover">
          <p>加入我们</p>
        </a>
      </li>

      
    </template>
  </navbar>
</template>

<script>
import { Navbar, DropDown, NavLink } from "@/components";
import { Popover } from "element-ui";
const WEBAPI = require("apifm-webapi");
WEBAPI.init2("https://api.it120.cc", "bamei");

export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    Navbar,
    DropDown,
    NavLink,
    [Popover.name]: Popover,
  },
  data() {
    return {
      proCategoryList: [],
      yyfaList: [],
    };
  },
  mounted() {
    this.cmsCategories();
  },
  methods: {
    async cmsCategories() {
      const res = await WEBAPI.cmsCategories();
      if (res.code === 0) {
        const list = res.data
        let proCategory = list.find((ele) => {
          return ele.key === "pros"
        })
        this.proCategoryList = list.filter(ele => { return ele.pid === proCategory.id })
        proCategory = list.find((ele) => {
          return ele.key === "yyfa"
        })
        this.yyfaList = list.filter(ele => { return ele.pid === proCategory.id })
      }
    },
    goIndex() {
      this.$router.push({
        path: "/",
        query: {},
      });
    },
    goProduct(id) {
      this.$router.push({
        path: "/product",
        query: { id: id, rnd: Math.random() },
      });
    },
    goYyfa(id) {
      this.$router.push({
        path: "/yyfa?id=" + id
      });
    },
    goService() {
      this.$router.push({
        path: "/service",
        query: {},
      });
    },
    goAbout() {
      this.$router.push({
        path: "/about",
        query: {},
      });
    },
    goNews() {
      this.$router.push({
        path: "/news",
        query: {},
      });
    },
    goJoin() {
      this.$router.push({
        path: "/join",
        query: {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  font-size: 18px;
  p {
    color: black !important;
  }
}

.nav-item:hover {
  font-weight: bold;
  // border-bottom: 2px solid black;
  cursor: pointer;
}

.invision-logo {
  max-width: 160px !important;
}

a:hover {
  font-weight: bold;
  // border-bottom: 2px solid black;
  cursor: pointer;
}
</style>
